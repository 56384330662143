import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../assets/styles/Portfolio.css'; // Import the CSS file for styles
import Modal from 'react-modal';

// Define the types of draggable items
const ItemTypes = {
  TAG: 'tag',
};

const Tag = ({ tag, onMove }) => {
    const [, ref] = useDrag({
      type: ItemTypes.TAG, // Set the type of draggable item
      item: { tag }, // Define the item being dragged
    });
  
    const handleClick = () => {
      onMove(tag); // Handle the click event to move the tag to the opposite list
    };
  
    return (
      <button ref={ref} className="tagbutton" onClick={handleClick}>
        <span>{tag}</span> {/* Display the tag */}
      </button>
    );
  };
  
// Component representing the drop zone where tags can be dropped
const DropZone = ({ title, tags, onDrop, onMove, dropType }) => {
    const [, ref] = useDrop({
      accept: ItemTypes.TAG, // Specify the type of item this zone can accept
      drop: (item) => onDrop(item.tag, dropType), // Handle the drop event
    });
  
    return (
      <div ref={ref} className="dropzone">
        <h3>{title}</h3> {/* Display the title of the drop zone */}
        {tags.length === 0 && dropType === 'include' && (
          <p className="placeholder-text">Drag/Click project tags here to view specific projects</p>
        )}
        {tags.map((tag) => (
          <Tag key={tag} tag={tag} onMove={onMove} /> // Render each tag in the drop zone
        ))}
      </div>
    );
  };
  

  const ProjectCard = ({ project }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
  
    return (
      <div className="group flex flex-col justify-start items-start gap-2 w-96 h-48 duration-500 relative rounded-lg p-4 bg-gray-900 hover:-translate-y-2 hover:shadow-xl shadow-gray-800">
        <div
          className="absolute duration-700 shadow-md group-hover:-translate-y-4 group-hover:-translate-x-4 -bottom-10 -right-10 w-1/2 h-1/2 rounded-lg bg-gray-800"
          style={{ backgroundImage: `url(${project.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          alt={project.name}
          onClick={openModal}
        ></div>
  
        <div>
          <h2 className="text-lg font-bold mb-2 text-gray-100">{project.name}</h2>
          <p className="text-gray-200 text-xs line-clamp-3">{project.description}</p>
        </div>
        <div className="mt-4 flex flex-wrap">
          {project.tags.map((tag, index) => (
            <button key={index} className="hover:bg-gray-700 bg-gray-800 text-gray-100 rounded p-1 px-2 mr-2 text-xs">
              {tag}
            </button>
          ))}
        </div>
  
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">Close</button>
          <img src={project.image} alt={project.name} className="w-full h-auto" />
        </Modal>
      </div>
    );
  };
// Main Portfolio component
const Portfolio = () => {
    const [projects, setProjects] = useState([]); // State to store projects data
    const [includedTags, setIncludedTags] = useState([]); // Tags in the "Include Tags" section
    const [excludedTags, setExcludedTags] = useState([]); // Tags in the "Exclude Tags" section
  
    // Fetch projects data from the JSON file on component mount
    useEffect(() => {
      fetch('/Json/portfolio.json')
        .then((response) => response.json())
        .then((data) => {
          setProjects(data); // Set the fetched projects data
          const allTags = Array.from(new Set(data.flatMap(project => project.tags)));
          setExcludedTags(allTags); // Initialize all tags in the "Exclude Tags" list
        })
        .catch((error) => console.error('Error fetching portfolio data:', error));
    }, []);
  
    // Function to handle moving tags between Include and Exclude sections
    const moveTag = (tag) => {
      if (includedTags.includes(tag)) {
        setIncludedTags(includedTags.filter(t => t !== tag));
        setExcludedTags([...excludedTags, tag]);
      } else {
        setExcludedTags(excludedTags.filter(t => t !== tag));
        setIncludedTags([...includedTags, tag]);
      }
    };
  
    // Function to handle drop event
    const handleDrop = (tag, targetList) => {
      if (targetList === 'include') {
        if (!includedTags.includes(tag)) {
          moveTag(tag);
        }
      } else if (targetList === 'exclude') {
        if (!excludedTags.includes(tag)) {
          moveTag(tag);
        }
      }
    };
  
    // Function to filter projects based on included tags
    const filteredProjects = projects.filter(project =>
      includedTags.some(tag => project.tags.includes(tag))
    );
  
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="portfolio-container">
          {/* Display the Include and Exclude tag sections */}
          <div className="buttons-container">
            <DropZone
              title="Displayed Projects"
              tags={includedTags} // Tags in the Include section
              onDrop={handleDrop} // Function to handle tag drop
              onMove={moveTag} // Function to handle tag move on click
              dropType="include" // Indicate that this zone is for including tags
            />
            <DropZone
              title="Select Projects"
              tags={excludedTags} // Tags in the Exclude section
              onDrop={handleDrop} // Function to handle tag drop
              onMove={moveTag} // Function to handle tag move on click
              dropType="exclude" // Indicate that this zone is for excluding tags
            />
          </div>
  
          {/* Display the filtered projects based on selected tags */}
          <div className="projects-display">
            <h2 className="projects-heading">Projects</h2>
            <div className="projects-list">
              {filteredProjects.map((project) => (
                <ProjectCard key={project.name} project={project} />
              ))}
            </div>
          </div>
              {/* Download Portfolio Button */}
              <div className="download-container">
              <a
  className="flex items-center gap-1 hover:gap-3 rounded-full bg-gradient-to-r from-pink-500 to-violet-600 px-3 md:px-8 py-3 md:py-4 text-center text-xs md:text-sm font-medium uppercase tracking-wider text-white no-underline transition-all duration-200 ease-out hover:text-white hover:no-underline md:font-semibold"
  role="button"
  target="_blank"
  rel="noopener noreferrer"
  href="https://drive.google.com/file/d/1kEm7HSpqYsdYNsNzirrzCZMlLxZDeVrX/view?usp=sharing"
>
  <span>Download Complete Portfolio</span>
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
  </svg>
</a>

        </div>
      </div>
    </DndProvider>
  );
};

export default Portfolio;