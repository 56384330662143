import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

import '../assets/styles/SwitchStyles.css'; // Import the switch styles

function Header() { // Accept the toggle function as a prop
  const [navOpen, setNavOpen] = useState(false);

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  const handleNavLinkClick = () => {
    setNavOpen(false);
  };

  return (
    <header className="text-white py-5">
      <nav className="container mx-auto flex items-center justify-between">
        {/* Logo / Name */}
        <div className="flex flex-shrink-0 items-center">
          <Link to="/" className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 animate-text text-xl font-extrabold">
            Sardar Mohammad Khan
          </Link>
        </div>

        {/* Navbar Links */}
        <ul className={`flex-col md:flex-row md:space-x-1 flex h-screen max-h-0 w-full items-start md:items-center text-sm md:h-auto md:max-h-screen md:w-auto md:border-0 md:opacity-100 transition-opacity duration-300 ${navOpen ? 'opacity-100' : 'opacity-0 md:opacity-100'}`}>
          <li>
            <Link to="/" onClick={handleNavLinkClick} className="block px-4 py-2 no-underline outline-none hover:no-underline">
              <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">
                Home
              </div>
            </Link>
          </li>
          <li>
            {/* <Link to="/education" onClick={handleNavLinkClick} className="block px-4 py-2 no-underline outline-none hover:no-underline">
              <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">
                Education
              </div>
            </Link> */}
          </li>
          <li>
            <Link to="/experience" onClick={handleNavLinkClick} className="block px-4 py-2 no-underline outline-none hover:no-underline">
              <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">
                Experience
              </div>
            </Link>
          </li>
          <li>
            <Link to="/projects" onClick={handleNavLinkClick} className="block px-4 py-2 no-underline outline-none hover:no-underline">
              <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">
                Projects
              </div>
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleNavLinkClick} className="block px-4 py-2 no-underline outline-none hover:no-underline">
              <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">
                Contact
              </div>
            </Link>
          </li>
        </ul>

        {/* Navbar toggle button on the left */}
        <div className="md:hidden">
          <button onClick={handleNavToggle} className="text-white text-2xl">
            {navOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

    
      </nav>
    </header>
  );
}

export default Header;





