import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
// import Chatbot from './components/Chatbot';  // Import Chatbot
import data from './data.json';
import './App.css'; // Import the CSS file



function App() {
 

  const [showChatbot, setShowChatbot] = useState(false);



  return (
    <Router>
      <div className="App">
        <Header />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="container mx-auto p-4"
        >
          <Routes>
          <Route path="/" element={<Home/>} />
           
            <Route path="/experience" element={<Experience experience={data.experience} />} />
            <Route path="/projects" element={<Projects projects={data.projects} />} />
            <Route path="/contact" element={<Contact contact={data.contact} />} />
          </Routes>
        </motion.div>
        <Footer />
        <div className="fixed bottom-4 right-4">
          <button
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg"
            onClick={() => setShowChatbot(!showChatbot)}
          >
            {/* Chatbot toggle button content */}
          </button>
        </div>
        {/* {showChatbot && (
          <div className="fixed bottom-20 right-4 bg-white border border-gray-300 shadow-lg rounded-lg w-80 h-96 p-4">
            <Chatbot onClose={() => setShowChatbot(false)} />
          </div>
        )} */}
      </div>
    </Router>
  );
}

export default App;
