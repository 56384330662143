import React from 'react';
import ProfilePic from '../assets/images/ProfilePic.jpg'; // Ensure this path is correct
import '../assets/styles/AboutMe.css'; // Import the CSS file for styles

const AboutMe = () => {
    return (
      <div className="about-me-container">
        {/* Card on the left */}
        <div className="card-container">
          <div className="card">
            <center>
              <div className="profileimage">
                <img src={ProfilePic} alt="Profile" className="pfp" />
              </div>
              <div className="socialbar">
            <a id="github" href="https://github.com/Mohammadk25" target="_blank" rel="noopener noreferrer">
              <svg viewBox="0 0 16 16" className="bi bi-github" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
              </svg>
            </a>
           
            &nbsp;&nbsp;&nbsp;
            <a id="linkedin" href="https://www.linkedin.com/in/mohammadk25/" target="_blank" rel="noopener noreferrer" >
              <svg viewBox="0 0 16 16" class="bi bi-linkedin" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                 <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zM4.943 12.508V6.169H3.103v6.339h1.84zm-.92-7.208c.653 0 1.079-.432 1.079-.97-.012-.552-.426-.971-1.065-.971-.64 0-1.08.419-1.08.97 0 .538.426.971 1.052.971h.014zm3.725 7.208h1.839V9.557c0-.158.012-.316.058-.428.128-.315.419-.641.907-.641.64 0 .896.484.896 1.194v2.826h1.839V9.491c0-1.641-.875-2.406-2.042-2.406-1.005 0-1.451.558-1.696.955h.014v-.819H7.749c.026.532 0 6.339 0 6.339h.014zM1.875 6.169h1.839v6.339H1.875V6.169zM3.944 4.104c-.703 0-1.159-.478-1.159-1.078 0-.614.471-1.079 1.187-1.079.72 0 1.159.465 1.159 1.079 0 .614-.471 1.078-1.187 1.078H3.944z"/>
              </svg>
              </a>
                            &nbsp;&nbsp;&nbsp;
            <a id="whatsapp" href="https://wa.me/923133377667" target="_blank" rel="noopener noreferrer" >
                 <svg viewBox="0 0 16 16" class="bi bi-whatsapp" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6 2.3A7.44 7.44 0 0 0 8.92 0C4.82 0 1.61 3.21 1.61 7.19c0 1.3.37 2.53 1.08 3.62L0 16l5.4-2.15c1.06.58 2.23.89 3.52.89 4.09 0 7.19-3.21 7.19-7.19a7.35 7.35 0 0 0-2.5-5.35zM8.91 14.69c-1.21 0-2.38-.32-3.42-.94L2.71 14l1.17-2.61a6.51 6.51 0 0 1-1.02-3.3A6.19 6.19 0 0 1 8.92 1.6a6.3 6.3 0 0 1 6.29 6.3c0 3.46-2.82 6.29-6.29 6.29z"/>
                     <path d="M11.18 9.89c-.17-.08-1.02-.5-1.17-.56-.16-.06-.28-.08-.4.09-.12.16-.46.56-.56.68-.1.12-.2.13-.37.05-.17-.08-.72-.27-1.37-.8-.5-.44-.83-.98-.93-1.15-.1-.17-.01-.25.08-.34.07-.08.16-.2.25-.3.08-.1.1-.17.17-.28.06-.12.03-.22-.01-.3-.04-.08-.4-1.03-.55-1.43-.14-.35-.29-.3-.4-.31h-.34c-.12 0-.3.04-.47.22-.16.17-.63.6-.63 1.46s.65 1.69.75 1.81c.1.13 1.27 1.94 3.07 2.72.43.19.77.31 1.03.4.43.14.83.12 1.14.07.35-.05 1.07-.44 1.22-.86.15-.41.15-.75.11-.83-.04-.08-.15-.13-.31-.22z"/>
                     </svg>
                    </a>

                    &nbsp;&nbsp;&nbsp;
            <a id="instagram" href="https://www.instagram.com/mohammaddkhan__" target="_blank" rel="noopener noreferrer" >
              <svg viewBox="0 0 16 16" className="bi bi-instagram" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
              </svg>
            </a>
          </div>
        </center>
      </div>
    </div>

   {/* About Me section on the right */}
   <div className="about-me-box">
   <h2 class="text-white font-bold mb-4">About Me</h2>

        <p>
          I am Sardar Mohammad Khan, a passionate Full Stack Developer who loves creating with 
          <span className="highlight-gradient"> JavaScript</span>, 
          <span className="highlight-gradient"> Python</span>, and 
          <span className="highlight-gradient"> SQL</span>—whether it's developing web apps or designing user interfaces. My expertise spans multiple domains, bringing ideas to life. With a strong background in web development, I have honed my skills in front-end and back-end technologies, and I am always eager to learn and adopt new technologies to improve my craft.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
