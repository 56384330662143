import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../assets/styles/Projects.css'; // Import the CSS file for styles

function Projects() {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://api.github.com/users/Mohammadk25/repos')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => {
        console.error('Error fetching GitHub repos:', error);
        setError('Failed to load projects. Please try again later.');
      });
  }, []);

  return (
    <section className="projects-container">
      <h2 className="projects-heading">Projects</h2>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        projects.map((project, index) => (
          <motion.div key={index} className="project-card"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="project-title">{project.name}</h3>
            <p className="project-description">{project.description}</p>
            <a href={project.html_url} className="project-link">View on GitHub</a>
          </motion.div>
        ))
      )}
    </section>
  );
}

export default Projects;
