import React, { useEffect, useState } from 'react';
import '../assets/styles/Skills.css'; // Import the CSS file for styles

function Skills() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    // Fetch the skills data from the public folder
    fetch('/Json/skills.json')
      .then(response => response.json())
      .then(data => setSkills(data))
      .catch(error => console.error('Error fetching skills:', error));
  }, []);

  return (
    
    
    <div className="skills-container">
      
      <div className="skills-heading">
        <span className="line"></span>
        <span className="heading-text">Skills</span>
        <span className="line"></span>
      </div>

      <div className="skills-cloud">
        <ul className="scroller__inner">
          {skills.map((skill, index) => (
            <li key={index} className="skill-item">
              
              <div className="skill-icon">
                <img src={skill.img} alt={skill.name} className="skill-img" />
              </div>
              <p className="skill-name">{skill.name}</p>
            </li>
          ))}
          {skills.map((skill, index) => (
            <li key={`duplicate-${index}`} className="skill-item" aria-hidden="true">
              <div className="skill-icon">
                <img src={skill.img} alt={skill.name} className="skill-img" />
              </div>
              <p className="skill-name">{skill.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Skills;
