import React, { useEffect, useState } from 'react';
import '../assets/styles/Experience.css';

const Experience = () => {
  const [experienceData, setExperienceData] = useState([]);

  useEffect(() => {
    // Fetch data from the JSON file
    fetch('/Json/experienceData.json')
      .then((response) => response.json())
      .then((data) => setExperienceData(data))
      .catch((error) => console.error('Error fetching experience data:', error));
  }, []);

  return (
    <div className="experience-container">
      {experienceData.map((experience, index) => (
        <div key={index} className="card2-container">
          <div className="card2">
            <div className="img-content">
              <img src={experience.image} alt={experience.title} className="experience-img" />
            </div>
            <div className="content">
              <p className="heading">{experience.title}</p>
              <p>{experience.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};



export default Experience;
