import React from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaPhone } from 'react-icons/fa';
import ContactForm from './ContactForm';
import '../assets/styles/Contact.css'; // Import the CSS file for styles

function Contact({ contact }) {
  const handlePhoneClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(contact.phone);
      alert('Phone number copied to clipboard!');
    } else {
      window.open(`https://wa.me/${contact.phone}`);
    }
  };

  return (
    <div className="contact-container">

      <ContactForm />

      <section className="contact-details-container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div className="contact-card"
            whileHover={{ scale: 1.05 }}
          >
            <FaPhone className="contact-icon" />
            <div>
              <h3 className="contact-card-heading">Phone</h3>
              <button onClick={handlePhoneClick} className="contact-card-link">
                {contact.phone}
              </button>
            </div>
          </motion.div>

          <motion.div className="contact-card"
            whileHover={{ scale: 1.05 }}
          >
            <FaEnvelope className="contact-icon" />
            <div>
              <h3 className="contact-card-heading">Email</h3>
              <a href={`mailto:${contact.email}`} className="contact-card-link">
                {contact.email}
              </a>
            </div>
          </motion.div>

          <motion.div className="contact-card"
            whileHover={{ scale: 1.05 }}
          >
            <FaGithub className="contact-icon" />
            <div>
              <h3 className="contact-card-heading">GitHub</h3>
              <a href={`https://${contact.github}`} className="contact-card-link">
                {contact.github}
              </a>
            </div>
          </motion.div>

          <motion.div className="contact-card"
            whileHover={{ scale: 1.05 }}
          >
            <FaLinkedin className="contact-icon" />
            <div>
              <h3 className="contact-card-heading">LinkedIn</h3>
              <a href={`https://${contact.linkedin}`} className="contact-card-link">
                {contact.linkedin}
              </a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
