import React, { useState } from 'react';
import Portfolio from './Portfolio';
import TerminalCard from '../assets/stylejs/TerminalCard';
import Skills from '../components/Skills';
import CoderCard from '../components/CoderCard'; 
import AboutMe from '../components/AboutMe';
import { Link } from 'react-router-dom'; 

function Home() {
  const [showResumeOptions, setShowResumeOptions] = useState(false);

  const handleResumeClick = () => {
    setShowResumeOptions(!showResumeOptions);
  };

  return (
    <div className="container mx-auto p-6 relative">
      <div className="flex flex-col lg:flex-row items-center text-white p-8 rounded-lg mb-10">
        <div className="terminal-card-position">
          <TerminalCard />
        </div>

        <div className="text-center lg:text-left">
          <h1 className="text-4xl lg:text-5xl font-extrabold mb-4">
            Hello,
          </h1>
          <h2 className="text-4xl lg:text-5xl font-extrabold mb-4">
            This is&nbsp;  
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 animate-text">
               Mohammad
            </span>
          </h2>
          <p className="text-4xl lg:text-5xl font-extrabold mb-4">
            A Full Stack Developer
          </p>

                
            <div className="flex justify-center lg:justify-start gap-4 mt-6">
            {/* Contact Me Button */}
            <Link to="/contact" className="bg-gradient-to-r to-pink-500 from-violet-600 p-[1px] rounded-full transition-all duration-300 hover:from-pink-500 hover:to-violet-600">
              <button className="px-3 text-xs md:px-8 py-3 md:py-4 bg-[#0d1224] rounded-full border-none text-center md:text-sm font-medium uppercase tracking-wider text-[#ffff] no-underline transition-all duration-200 ease-out  md:font-semibold flex items-center gap-1 hover:gap-3">
                <span>Contact Me</span>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM20 17H24V19H20V17ZM17 12H24V14H17V12ZM19 7H24V9H19V7Z"></path>
                </svg>
              </button>
            </Link>




            {/* Resume Button with Dropdown */}
            <div className="relative">
              <button
                onClick={handleResumeClick}
                className="bg-gradient-to-r to-pink-500 from-violet-600 p-[1px] rounded-full transition-all duration-300 hover:from-pink-500 hover:to-violet-600"
              >
                <span className="flex items-center gap-1 hover:gap-3 rounded-full bg-gradient-to-r from-pink-500 to-violet-600 px-3 md:px-8 py-3 md:py-4 text-center text-xs md:text-sm font-medium uppercase tracking-wider text-white no-underline transition-all duration-200 ease-out hover:text-white hover:no-underline md:font-semibold">
                  Download Resumes
                </span>
              </button>

              {showResumeOptions && (
                <div className="absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <div className="py-1">
                    <a href="https://drive.google.com/file/d/1MyFkU08mIe_klVMgdAn9Cfzqww6y0wkX/view?usp=sharing" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Python Developer
                    </a>
                    <a href="https://drive.google.com/file/d/1_eHWuoyK87zhWNbclA3DbCvRg36Eky4k/view?usp=sharing" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Web Developer
                    </a>
                    <a href="https://drive.google.com/file/d/1rFZZPZ9QHZuZ_aTZI8PcfKE1F2CYRmlP/view?usp=sharing" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      App Developer
                    </a>
                    <a href="https://drive.google.com/file/d/1Jn0y9FYyKCVTIrB4Mwvs1yDnZVIJCgbl/view?usp=sharing" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Software Quality Assurance
                    </a>
                    <a href="https://drive.google.com/file/d/1KVX09HYYeKZ0Cniy3y3ETa5bvBDTzPQM/view?usp=sharing" target="_blank" rel="noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Data Analysis
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CoderCard />
      <AboutMe />
      <Skills />
      <Portfolio />
    </div>
  );
}

export default Home;
